$(function () {
	// the search modal requires the various metadata to be loaded: list of tags, categories etc. Request it immediately,
	// or pull from session storage if it's already loaded
	var searchMetadata = sessionStorage.getItem('searchMetadata');
	if (!searchMetadata) {
		requestSearchMetaData();
	} else {
		initMetadata(JSON.parse(searchMetadata));
	}

	// the tag pills in the page
	$('#page-tags span').on('click', onClickPageTags);

	// the tag pills in the search modal. The ones in the search results and top section behave the same way
	$('body').on('click', '#search-tags span', onSelectTags);
	$('body').on('click', '#search-results .search-result-tags span', onSelectTags);

	// the search button in the page
	$('#search-panel .search-button').on('click', function () {
		// how to behave? Show last search?
		$('#search-modal').modal('show');
	});

	// the search button in the search modal
	$('body').on('click', '#search-btn', submitSearch);
	$('body').on('submit', '#search-modal form', submitSearch);

	// the reset search form link
	$('#clear-form').on('click', function (e) {
		e.preventDefault();
		clearForm();
	});

	$('#search-modal').on('show.bs.modal', function () {
		$(this).css('display', 'flex');

		var searchStr = sessionStorage.getItem('searchString');
		if (searchStr) {
			$('.search-field').val(searchStr);
		}
		var cat = sessionStorage.getItem('cat');
		if (cat) {
			$('#search-categories').val(cat);
		}
		search();
	});
	$('#search-modal').on('shown.bs.modal', function () {
		$('.search-field').select().focus();
	});
	$('#search-modal').on('hidden.bs.modal', function () {
		$(this).css('display', 'none');
	});
});

var onClickPageTags = function (e) {
	var tag = e.target.className;

	// select the tag in the search modal and clear any previous search criteria
	sessionStorage.setItem('tags', JSON.stringify([tag]));
	sessionStorage.setItem('cat', '');
	sessionStorage.setItem('searchString', '');

	$('#search-modal .page-tags span').addClass('unselected');
	$(`#search-modal .${tag}`).removeClass('unselected');
	$('#search-modal').modal("show");

	// now trigger a search
	search();
};


var onSelectTags = function (e) {
	var tags = sessionStorage.getItem('tags');

	var selectedTag;
	var remainingTags = [];

	var targetTag = e.target.className;
	var targetTagWithoutSelect = $.trim(targetTag.replace(/unselected/, ''));

	var el = $(`#search-tags .${targetTagWithoutSelect}`);

	// selecting a tag
	if (el.hasClass('unselected')) {
		el.removeClass('unselected');
		selectedTag = e.target.className;

		if (tags) {
			remainingTags = JSON.parse(tags);
			remainingTags.push(selectedTag);
			sessionStorage.setItem('tags', JSON.stringify(remainingTags));
		} else {
			remainingTags = [selectedTag];
			sessionStorage.setItem('tags', JSON.stringify(remainingTags));
		}

	// unselecting a tag
	} else {
		selectedTag = e.target.className;
		el.addClass('unselected');

		if (tags) {
			var existingTags2 = JSON.parse(tags);
			var index =  existingTags2.indexOf(selectedTag);
			if (index !== -1) {
				existingTags2.splice(index, 1);
			}
			sessionStorage.setItem('tags', JSON.stringify(existingTags2));
		} else {
			sessionStorage.setItem('tags', JSON.stringify([]));
		}
	}

	search();
};


var requestSearchMetaData = function () {
	$.ajax({
		url: `${C.URLS.FT}/ajax/docs-metadata.php`,
		type: 'post',
		success: initMetadata
	});
};

// called onload when the user already has the metadata loaded
var initMetadata = function (data) {
	sessionStorage.setItem('searchMetadata', JSON.stringify(data));

	// load the categories
	var options = '<option value="">All Sections</option>';
	for (var i=0; i<data.cats.length; i++) {
		options += `<option value="${data.cats[i].slug}">${data.cats[i].name} (${data.cats[i].count})</option>`;
	}
	$('#search-categories').html(options);

	// load the tags
	var tags = sessionStorage.getItem('tags');
	if (tags) {
		tags = JSON.parse(tags);
	} else {
		tags = [];
	}

	var tagsHtml = '';
	for (var i=0; i<data.tags.length; i++) {
		var className = (tags.indexOf(data.tags[i].slug) === -1) ? ' unselected' : '';
		tagsHtml += `<li><span class="${data.tags[i].slug}${className}">${data.tags[i].name} (${data.tags[i].count})</span></li>`;
	}
	$("#search-tags").html(`<ul class="page-tags">${tagsHtml}</ul>`);
};

var search = function () {
	var tags = sessionStorage.getItem('tags');
	var cat = sessionStorage.getItem('cat');
	var searchString = sessionStorage.getItem('searchString');

	var hasTags = true;
	if (tags === null) {
		hasTags = false;
	} else {
		hasTags = JSON.parse(tags).length > 0;
	}

	if (!searchString && !cat && !hasTags) {
		$('#no-results').css('display', 'flex');
		$('#num-results').hide();
		$('#search-results').hide();
		return;
	}

	$.ajax({
		url: `${C.URLS.FT}/ajax/docs-search.php`,
		type: 'post',
		data: {
			searchString: searchString,
			cat: cat,
			tags: JSON.parse(tags)
		},
		success: displaySearchResults
	});
};

var displaySearchResults = function ({ num_results: numResults, results }) {
	if (numResults === 0) {
		$('#no-results').show();
		$('#loading-spinner').hide();
		$('#num-results').hide();
		$('#search-results').hide().html();
		return;
	}

	var html = '<ul class="search-results">';
	for (var i=0; i<results.length; i++) {
		var tags = results[i].tags.split(",");

		var tagsHtml = '<ul class="page-tags search-result-tags">';
		for (var j=0; j<tags.length; j++) {
			var tagLabel = formatTag(tags[j]);
			tagsHtml += `<li><span class="${tags[j]}">${tagLabel}</span></li>`;
		}
		tagsHtml += '</ul>';

		html += `<li>${tagsHtml}<h2><a href="${C.URLS.DOCS}${results[i].page_path}">${results[i].header}</a></h2>`
			 + `<div><a href="${C.URLS.DOCS}${results[i].page_path}" class="page-path">${C.URLS.DOCS}${results[i].page_path}</a></div>`
			 + `<p>${results[i].page_content}</p>`
			 + '</li>';
	}

	html += '</ul>';

	$('#no-results').hide();
	$("#loading-spinner").hide();
	$("#num-results").show().html(`Num results: <b>${numResults}</b>`);
	$("#search-results").show().html(html);
};

var formatTag = function (tag) {
	return tag.replace('_', ' ').replace(/\b\w/g, function(l) { return l.toUpperCase() });
};

var clearForm = function () {
	sessionStorage.setItem('tags', JSON.stringify([]));
	sessionStorage.setItem('cat', '');
	sessionStorage.setItem('searchString', '');
	$('#search-results,#num-results').hide();
	$('.search-field,#search-categories').val('');
	$('#search-modal .page-tags span').addClass('unselected');
	search();
};


// when the user clicks the search btn in the modal, or clicks <enter>
var submitSearch = (e) => {
	e.preventDefault();
	sessionStorage.setItem('searchString', $('.search-field').val());
	sessionStorage.setItem('cat', $('#search-categories').val());
	search();
}
